import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";

export default function RoomAssistant() {

  const data = useStaticQuery(graphql`{
  ra: file(relativePath: {eq: "roomassistant3.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  ra2: file(relativePath: {eq: "roomassistant2.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`)

 


  return (
    <Layout>
      <SEO title="Referencie" />
      <GatsbyImage
        image={data.ra.childImageSharp.gatsbyImageData}
        objectFit="cover"
        objectPosition="0% 0%"
        alt="Mobilná aplikácia" />  
      <div className="section-pd section-pd-bottom white-bg">
        <div className="container">
          <div className="row">
          <div className="col-12">
              <h1>Web, mobilná a webová aplikácia pre RoomAssistant</h1>
              <p>RoomAssistant je mobilná aplikácia pre hotely, ktorá vytvára nový kanál digitálnej komunikácie medzi hosťami hotelov a samotnými hotelmi. Hostia v aplikácii si môžu objednať večeru na izbu, objednať si masáž alebo sa dozvedieť viac o okolí hotela.
<br></br><br></br>
Elliate je hrdým partnerom spoločnosti RoomAssistant. Postavili sme webovú stránku a podieľali sme sa na celkovom vývoji aplikácie a administrácie. Teraz prevádzkujeme kampane a aktualizácie aplikácie.</p>
            </div>
            
          </div>
          
        </div>
      </div>
      <GatsbyImage
        image={data.ra2.childImageSharp.gatsbyImageData}
        objectFit="cover"
        objectPosition="0% 0%"
        alt="Web aplikácia" />  
         
          

      
      



      <div className="ref-buttons">
        <AniLink className="back-to-ref" cover direction="left" to="/referencie" bg="#000" duration={1}>Späť na referencie</AniLink>
        <AniLink className="next-project" cover direction="left" to="/referencie/drivelog" bg="#000" duration={1}>Ďalší projekt</AniLink>
        
      </div>

    </Layout>
  );

}
